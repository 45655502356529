
import { defineComponent, ref, computed, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "KTActivityMenu",
  setup() {
    const { t } = useI18n();
    const nameMenu = ref(t("recentlyActivity"));
    const recently_activities = computed(() =>
      JSON.parse(localStorage.getItem("logs_activities") || "{}")
    );
    onMounted(() => {
      console.log(
        "recently_activities recently_activities",
        recently_activities.value
      );
    });
    const onClick = (item) => {};
    return {
      recently_activities,
      nameMenu,
      onClick,
      sita_path: document.location.origin,
    };
  },
});
