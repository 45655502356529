
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onUpdated,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "KTActivityLogs",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();
    const nameMenu = ref(t("recentlyActivity"));
    const loadingHistorical = computed(
      () => store.getters.GetLoadingHistorical
    );
    const historical = computed(() =>
      JSON.parse(localStorage.getItem("logs_activities") || "{}")
    );
    let recently_activities = ref([] as any);
    const activeAccordion = ref("1");
    const onClick = (item, event) => {
      event.preventDefault();
      console.log("onClick item", item);
      if (item.tag === "maritime_files") {
        router.push("/maritimefile/" + item.maritime_id);
      }
      if (item.tag === "bookings") {
        localStorage.setItem("booking_id", item.booking_id);
        store.commit("setMaritimeFileTab", {
          tab: "bookings",
          item_id: item.booking_id,
          from: "bookins_view",
        });
        router.push({
          name: "maritimefileEdit",
          params: { id: item.maritime_file_id },
        });
      }
      if (item.tag === "bls") {
        store.commit("setMaritimeFileTab", {
          tab: "billOfLading",
          item_id: item.bl_id,
          from: "messagingpanle",
        });
        router.push("/maritimefile/" + item.maritime_file_id);
      }
      if (item.tag === "bill") {
        router.push("/bill_register/" + item.billing_id);
      }
    };

    watch(
      () => loadingHistorical.value,
      (first) => {
        console.log("watch loadingHistorical", loadingHistorical.value);
        if (loadingHistorical.value) {
          recently_activities.value = JSON.parse(
            localStorage.getItem("logs_activities") || "{}"
          );
          store.commit("setLoadingHistorical", false);
        }
      }
    );
    onMounted(() => {
      recently_activities.value = historical.value;
      console.log("onMounted recently_activities", recently_activities.value);
    });
    return {
      recently_activities,
      nameMenu,
      activeAccordion,
      onClick,
    };
  },
});
