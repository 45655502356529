import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_header",
  style: {},
  class: "header align-items-stretch"
}
const _hoisted_2 = {
  class: "d-flex align-items-center d-lg-none ms-n3 me-1",
  title: "Show aside menu"
}
const _hoisted_3 = {
  id: "kt_aside_mobile_toggle",
  class: "btn btn-icon btn-active-light-primary"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2x mt-1" }
const _hoisted_5 = { class: "d-flex align-items-center flex-grow-1 flex-lg-grow-0" }
const _hoisted_6 = {
  href: "#",
  class: "d-lg-none"
}
const _hoisted_7 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }
const _hoisted_8 = {
  id: "kt_header_menu_nav",
  class: "d-flex align-items-stretch"
}
const _hoisted_9 = { class: "d-flex align-items-stretch flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, {
              src: _ctx.sita_path + 'media/icons/duotune/abstract/abs015.svg'
            }, null, 8, ["src"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          _createVNode(_component_inline_svg, {
            alt: "Logo",
            src: _ctx.sita_path + 'media/logos/logo-2.svg',
            class: "h-30px"
          }, null, 8, ["src"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_KTMenu)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_KTTopbar)
        ])
      ])
    ], 2)
  ]))
}