
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import store from "@/store";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "KtMenu",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    // const user = computed(() => store.getters.currentUser);
    const u = localStorage.getItem("user") as any;
    const user = JSON.parse(u);
    const scrollElRef = ref<null | HTMLElement>(null);
    const showBackDialog = ref(false);
    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
      setTimeout(function () {
        getPermissions();
      }, 2000);
    });
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const permissions = ref([]);

    const leaveDialog = () => {
      store.commit("setsaveForm", false);
      showBackDialog.value = false;
    };

    const cancelDialog = () => {
      // store.commit('setsaveForm',false);
      showBackDialog.value = false;
    };
    const goToRoute = (menu) => {
      console.log("gotoRoute", menu);
      router.push({ path: menu.route });
    };
    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const getPermissions = () => {
      ApiService.get(`/api/permissions/me`)
        .then(({ data }) => {
          permissions.value = data;
        })
        .catch((error) => {
          console.log(error);
          permissions.value = [];
        });
    };

    const hasPermission = (permission) => {
      return (
        permissions.value.filter((x: any) => x.name === permission).length > 0
      );
    };

    return {
      hasPermission,
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      showBackDialog,
      version,
      translate,
      goToRoute,
      cancelDialog,
      leaveDialog,
      permissions,
    };
  },
});
