import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "scroll-y mh-325px my-5 px-8" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "symbol symbol-35px me-4" }
const _hoisted_5 = { class: "bg-light-primary symbol-label" }
const _hoisted_6 = { class: "bg-light-primary svg-icon svg-icon-2" }
const _hoisted_7 = { class: "mb-0 me-2" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "badge badge-light fs-8" }
const _hoisted_10 = { class: "col-12 d-flex flex-stack py-4" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = { class: "symbol symbol-35px me-4" }
const _hoisted_13 = { class: "bg-light-primary symbol-label" }
const _hoisted_14 = { class: "bg-light-primary svg-icon svg-icon-2" }
const _hoisted_15 = { class: "mb-0 me-2" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "badge badge-light fs-8" }
const _hoisted_18 = { class: "col-12 d-flex flex-stack py-4" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = { class: "symbol symbol-35px me-4" }
const _hoisted_21 = { class: "bg-light-primary symbol-label" }
const _hoisted_22 = { class: "bg-light-primary svg-icon svg-icon-2" }
const _hoisted_23 = { class: "mb-0 me-2" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "badge badge-light fs-8" }
const _hoisted_26 = { class: "col-12 d-flex flex-stack py-4" }
const _hoisted_27 = { class: "d-flex align-items-center" }
const _hoisted_28 = { class: "symbol symbol-35px me-4" }
const _hoisted_29 = { class: "bg-light-primary symbol-label" }
const _hoisted_30 = { class: "bg-light-primary svg-icon svg-icon-2" }
const _hoisted_31 = { class: "mb-0 me-2" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "badge badge-light fs-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_collapse, {
        modelValue: _ctx.activeAccordion,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeAccordion) = $event)),
        accordion: ""
      }, {
        default: _withCtx(() => [
          (_ctx.recently_activities.maritime_files)
            ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                key: 0,
                title: _ctx.$t('timaritimefilelist')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recently_activities.maritime_files.filter(
              (x) => x.visible
            ), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-12 d-flex flex-stack py-4",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("span", _hoisted_5, [
                            _createElementVNode("span", _hoisted_6, [
                              _createVNode(_component_inline_svg, {
                                src: item.icon
                              }, null, 8, ["src"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("a", {
                            href: "#",
                            onClick: ($event: any) => (_ctx.onClick(item, $event)),
                            class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
                          }, _toDisplayString(item.name), 9, _hoisted_8)
                        ])
                      ]),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(item.time), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (_ctx.recently_activities.bls)
            ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                key: 1,
                title: _ctx.$t('bills_of_lading')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recently_activities.bls.filter((x) => x.visible), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "d-flex flex-stack py-4",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("span", _hoisted_13, [
                              _createElementVNode("span", _hoisted_14, [
                                _createVNode(_component_inline_svg, {
                                  src: item.icon
                                }, null, 8, ["src"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("a", {
                              href: "#",
                              onClick: ($event: any) => (_ctx.onClick(item, $event)),
                              class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
                            }, _toDisplayString(item.name), 9, _hoisted_16)
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_17, _toDisplayString(item.time), 1)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (_ctx.recently_activities.bookings)
            ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                key: 2,
                title: _ctx.$t('bookings')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recently_activities.bookings.filter(
              (x) => x.visible
            ), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "d-flex flex-stack py-4",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("span", _hoisted_21, [
                              _createElementVNode("span", _hoisted_22, [
                                _createVNode(_component_inline_svg, {
                                  src: item.icon
                                }, null, 8, ["src"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("a", {
                              href: "#",
                              onClick: ($event: any) => (_ctx.onClick(item, $event)),
                              class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
                            }, _toDisplayString(item.name), 9, _hoisted_24)
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_25, _toDisplayString(item.time), 1)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (_ctx.recently_activities.billings)
            ? (_openBlock(), _createBlock(_component_el_collapse_item, {
                key: 3,
                title: _ctx.$t('billings')
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recently_activities.billings.filter(
              (x) => x.visible
            ), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-12 d-flex flex-stack py-4",
                      key: item.id
                    }, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("span", _hoisted_29, [
                              _createElementVNode("span", _hoisted_30, [
                                _createVNode(_component_inline_svg, {
                                  src: item.icon
                                }, null, 8, ["src"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("a", {
                              href: "#",
                              onClick: ($event: any) => (_ctx.onClick(item, $event)),
                              class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
                            }, _toDisplayString(item.name), 9, _hoisted_32)
                          ])
                        ]),
                        _createElementVNode("span", _hoisted_33, _toDisplayString(item.time), 1)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}