
import { defineComponent } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "NotificationsMenu",
  components: {},
  setup() {
    const data1 = [
      {
        title: "Notificación 1",
        description: "Phase 1 development",
        time: "1 hr",
        icon: "media/icons/duotune/technology/teh008.svg",
        state: "primary",
      },
      {
        title: "Notificación 2",
        description: "Confidential staff documents",
        time: "2 hrs",
        icon: "media/icons/duotune/general/gen044.svg",
        state: "danger",
      },
      {
        title: "Notificación 3",
        description: "Corporeate staff profiles",
        time: "5 hrs",
        icon: "media/icons/duotune/finance/fin006.svg",
        state: "warning",
      },
      {
        title: "Notificación 4",
        description: "New frontend admin theme",
        time: "2 days",
        icon: "media/icons/duotune/files/fil023.svg",
        state: "success",
      },
      {
        title: "Notificación 5",
        description: "Product launch status update",
        time: "21 Jan",
        icon: "media/icons/duotune/maps/map001.svg",
        state: "primary",
      },
      {
        title: "Notificación 6",
        description: "Collection of banner images",
        time: "21 Jan",
        icon: "media/icons/duotune/general/gen006.svg",
        state: "info",
      },
      {
        title: "Notificación 7",
        description: "Collection of SVG icons",
        time: "20 March",
        icon: "media/icons/duotune/art/art002.svg",
        state: "warning",
      },
    ];

    const data2 = [
      {
        code: "200 OK",
        state: "success",
        message: "New order",
        time: "Just now",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "New customer",
        time: "2 hrs",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Payment process",
        time: "5 hrs",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Search query",
        time: "2 days",
      },
      {
        code: "200 OK",
        state: "success",
        message: "API connection",
        time: "1 week",
      },
      {
        code: "200 OK",
        state: "success",
        message: "Database restore",
        time: "Mar 5",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "System update",
        time: "May 15",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "Server OS update",
        time: "Apr 3",
      },
      {
        code: "300 WRN",
        state: "warning",
        message: "API rollback",
        time: "Jun 30",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Refund process",
        time: "Jul 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Withdrawal process",
        time: "Sep 10",
      },
      {
        code: "500 ERR",
        state: "danger",
        message: "Mail tasks",
        time: "Dec 10",
      },
    ];

    return {
      data1,
      data2,
      getIllustrationsPath,
    };
  },
});
