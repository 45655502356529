import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "symbol symbol-20px me-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "menu-item px-3" }
const _hoisted_6 = { class: "symbol symbol-20px me-4" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('en') }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLang('en')))
      }, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("img", {
            class: "rounded-1",
            src: _ctx.sita_path + '/media/flags/united-states.svg',
            alt: "metronic"
          }, null, 8, _hoisted_4)
        ]),
        _createTextVNode(" English ")
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: "#",
        class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('es') }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLang('es')))
      }, [
        _createElementVNode("span", _hoisted_6, [
          _createElementVNode("img", {
            class: "rounded-1",
            src: _ctx.sita_path + '/media/flags/spain.svg',
            alt: "metronic"
          }, null, 8, _hoisted_7)
        ]),
        _createTextVNode(" Spanish ")
      ], 2)
    ])
  ]))
}