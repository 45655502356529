import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "scroll-y mh-325px my-5 px-8" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "symbol symbol-35px me-4" }
const _hoisted_5 = { class: "mb-0 me-2" }
const _hoisted_6 = {
  href: "#",
  class: "fs-6 text-gray-800 text-hover-primary fw-bolder"
}
const _hoisted_7 = { class: "text-gray-400 fs-7" }
const _hoisted_8 = { class: "badge badge-light fs-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data1, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "d-flex flex-stack py-4"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", {
                class: _normalizeClass([`bg-light-${item.state}`, "symbol-label"])
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass([`svg-icon-${item.state}`, "svg-icon svg-icon-2"])
                }, [
                  _createVNode(_component_inline_svg, {
                    src: item.icon
                  }, null, 8, ["src"])
                ], 2)
              ], 2)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", _hoisted_6, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(item.description), 1)
            ])
          ]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(item.time), 1)
        ]))
      }), 128))
    ])
  ]))
}