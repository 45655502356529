
import { computed, defineComponent, onMounted } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTActivityMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTActivityLogs from "@/layout/header/partials/ActivityLogs.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KtLanguageMenu from "@/layout/header/partials/LanguageMenu.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import Echo from "laravel-echo";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
declare const window: any;
window.Pusher = require("pusher-js");
const user = localStorage.getItem("user") as any;

export default defineComponent({
  name: "Topbar",
  components: {
    KTNotificationsMenu,
    KTActivityMenu,
    KTUserMenu,
    KtLanguageMenu,
    KTActivityLogs,
  },
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute() as any;
    const activeConversation = computed(
      () => store.getters.ActiveConversations
    );
    const avatar = computed(() => {
      console.log("avatar", store.getters.currentUser.avatar);
      console.log("user", user);
      return store.getters.currentUser.avatar
        ? store.getters.currentUser.avatar
        : user !== null
        ? JSON.parse(user).avatar
        : "media/defaults/avatar.png";
    });
    try {
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: "ASDASD2121",
        wsHost: document.location.hostname,
        // authHost: "http://localhost:8000/api",
        authEndpoint: process.env.VUE_APP_API_URL + "/api/broadcasting/auth",
        wsPort: 6001,
        forceTLS: false,
        disableStats: true,
        auth: {
          headers: {
            Authorization: `Bearer ${JwtService.getToken()}`,
          },
        },
      });
    } catch (e) {
      //
    }

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    onMounted(() => {
      try {
        window.Echo.private("messages." + JSON.parse(user).id).listen(
          "MessageEvent",
          (e) => {
            // console.log(e.message);
            derivateMessage(e.message);
          }
        );
      } catch (e) {
        // console.log(e);
      }
    });

    const derivateMessage = (message) => {
      switch (message.type) {
        case "message":
          if (activeConversation.value) {
            if (route.name === "messages") {
              if (message.conversation === activeConversation.value.id) {
                store.commit("addMessageToList", {
                  type: "in",
                  text: message.message,
                  name: activeConversation.value.name,
                  time: new Date(message.updated_at),
                  image: null,
                });
              }
              store.commit("updateConversationStatus", message);
            } else {
              try {
                // console.log(route.name.toString() ? route.name : "");
              } catch (e) {
                // console.log(e);
              }
              const id = message.user_to;
              ElNotification({
                title: message.user_from_data,
                message: message.message,
                type: "info",
                onClick: function () {
                  store.commit("setActiveConversation", {
                    id: message.conversation,
                    name: message.user_from_data,
                    is_online: 1,
                    created_at: new Date(),
                  });
                  store.commit("clearMessageList");
                  store.commit("setLoading", false);
                  ApiService.get(
                    "/api/message/conversation/" + message.conversation
                  ).then(function (response) {
                    response.data.messages.forEach(function (value) {
                      store.commit("addMessageToList", {
                        type:
                          value.user_from[0].id === JSON.parse(user).id
                            ? "out"
                            : "in",
                        image: value.user_from[0].avatar,
                        name: value.user_from[0].name,
                        time: value.created_at,
                        text: value.message,
                      });
                    });

                    router.push({
                      name: "messages",
                      params: { id: message.conversation },
                    });
                  });
                },
              });
            }
          }
          break;

        case "notif":
          ElNotification({
            title: message.user_from_data,
            message: message.message,
            type: "info",
          });
          break;

        default:
          break;
      }
    };

    return {
      avatar,
      signOut,
      setLang,
      user,
      currentLanguage,
      currentLanguageLocale,
      countries,
      activeConversation,
      sita_path: document.location.origin,
      sita_api:
        process.env.VUE_APP_API_URL === "/"
          ? document.location.origin
          : process.env.VUE_APP_API_URL,
    };
  },
});
